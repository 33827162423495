import g from './globals'

setTimeout(() => {
    if (g.elementInDom('.ecp_JoinList')) {
        const mailingListSubmit = document.querySelector('#mailingListSubmit'),
            firstName = document.querySelector('#mailingListFirstName'),
            lastName = document.querySelector('#mailingListLastName'),
            email = document.querySelector('#mailingListEmail'),
            phone = document.querySelector('#mailingListPhone'),
            birthdate = document.querySelector('#mailingListBirthdate'),
            fakeSubmit = document.querySelector('#mailingListFakeSubmit'),
            errorMessage = document.querySelector('.errorMessage'),
            hiddenForm = document.querySelector('#mailingListHiddenForm'),
            hiddenEmail = document.querySelector('#hiddenEmail'),
            hiddenFirstName = document.querySelector('#hiddenFirstName'),
            hiddenLastName = document.querySelector('#hiddenLastName'),
            hiddenPhone = document.querySelector('#hiddenPhone'),
            hiddenBirthdate = document.querySelector('#hiddenBirthdate'),
            hiddenState = document.querySelector('#hiddenState')

        const inputs = [firstName, lastName, email, phone, birthdate]

        fakeSubmit.addEventListener('click', (e) => {
            e.preventDefault()

            //Check for empty fields and progress to populating hidden form
            new Promise((resolve, reject) => {
                checkForEmpty(inputs, resolve, reject)
            })
            .then((inputs) => {
                //set hidden form fields
                hiddenFirstName.value = inputs[0].value
                hiddenLastName.value = inputs[1].value
                hiddenEmail.value = inputs[2].value
                hiddenPhone.value = (hiddenBirthdate.value  === '') ? '1234567890' : inputs[3].value
                hiddenBirthdate.value = inputs[4].value
                hiddenState.value = 'AL'

                mailingListSubmit.click()
            }) //submit hidden form
            .catch((error) => {
                console.log(error)
            })

            function checkForEmpty(inputs, resolve, reject) {
                let emptyCount = 0

                inputs.forEach((obj, index) => {
                    //error message handling
                    if (obj.value === '' && index !== 3) {
                        errorMessage.innerHTML += '<br>' + obj.getAttribute('placeholder') + ' field is required';
                        emptyCount++
                    } else {
                        errorMessage.innerHTML = '';
                    }
                })

                if (emptyCount > 0) {
                    reject(inputs)
                } else (
                    resolve(inputs)
                )
            }
        })
    }
}, 5000)
