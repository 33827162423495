import fancybox from '@fancyapps/fancybox'
import g from './globals'

if (g.elementInDom('[data-fancybox]')) {
    $(document).ready(function() {
        $('[data-fancybox]').fancybox({
            vimeo : {
                color : 'f00'
            },
            afterLoad: () => {
                $('.fancybox-video').first().attr('poster', '')
            }
        });
    });
}