import Macy from 'macy'

$(document).ready(() => {
    if ($('.gallery-wrapper').length) {
        var macyInstance = Macy({
            // See below for all available options.
            container: '.gallery-wrapper',
            columns: 3,
            trueOrder: true,
            margin: {
                x: 60,
                y: 60
            },
            breakAt: {
                480: {
                    margin: {
                      x: 40,
                      y: 40,
                    },
                    columns: 1
                  },
                760: {
                  margin: {
                    x: 40,
                    y: 40,
                  },
                  columns: 2
                },
                1024: {
                    margin: {
                      x: 40,
                      y: 40,
                    },
                    columns: 3
                  }
              },
              useContainerForBreakpoints: true
        });
    }  
})
