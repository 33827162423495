import g from './globals'

if (g.elementInDom('.select-form')) {
    const selectForm = document.querySelector('#select-form'),
          formElements = document.querySelectorAll('.form-element')

    document.addEventListener('input', (event) => {
        if (event.target.id !== 'select-form') return

        formElements.forEach((obj, index) => {
            obj.classList.toggle('form-active')
        })
    })
}