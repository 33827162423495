import scrollMagic from 'scrollmagic'

var modules = [
    '.content-image-callout',
    '.content-3-image-blocks',
    '.content-2-image-blocks',
    '.split-image-detail',
    '.content-split-left',
    '.content-split-right',
    '.shop-callout',
    '.event-block',
    '.content-scroll-slider',
    '.content-3-image-callout',
    '.content-block',
    '.image-callouts',
    '.content-links',
    '.gallery',
    '.quiz-slider',
    '.feature-slider',
    'feature_slider_alt',
    '.instagram-block',
    '.wine-club-callouts',
    '.wine-club-contact',
    '.split-image-content',
    '.split-image-content-alt',
    '.gift-callouts',
    '.contact-form',
    '.tripleseat-form',
]

var calculatedModules = []

function getModulesIndex(module) {
    var moduleId = '#' + module.slice(1) + '-'

    $(module).each((index, obj) => {
        calculatedModules.push(moduleId + index)
    })
}

function isFirstModule(module) {
    var firstModule = $('.modules .grid-container').first(),
        compareModule = $(module)

    if (firstModule.is(compareModule)) {
        return true
    } else {
        return false
    }
}

// Init Calculate Modules
new Promise((resolve, reject) => {
    modules.forEach((module) => {
        getModulesIndex(module)
    })
    resolve()
})
    // Init Animations
    .then(() => {
        calculatedModules.forEach((module) => {
            // Run animation if not the first module on load
            if (!isFirstModule(module)) {
                new scrollMagic.Scene({
                    triggerElement: module
                })
                    .setClassToggle(module + ' .anim', "anim-show")
                    .addTo(
                        new scrollMagic.Controller({
                            refreshInterval: 50,
                            globalSceneOptions: {
                                triggerHook: "onEnter",
                                offset: 200
                            }
                        })
                    )
            } else {
                $(module).children('div').each((index, obj) => {
                    $(obj).removeClass('anim')
                })
            }
        })
    })
    .catch((error) => {
        console.log(error)
    })