// Set the date we're counting down to
var bannerLive = document.querySelector('.banner-live'),
    bannerDefault = document.querySelector('.banner-default'),
    now = new Date().getDate(),
    startDate = new Date(bannerLive.dataset.startDate).getDate(),
    endDate = new Date(bannerLive.dataset.endDate).getDate(),
    distance = endDate - now;

if (now >= startDate && now <= endDate) {
    bannerLive.classList.remove('hide-banner')
    bannerDefault.classList.add('hide-banner')
} else {
    bannerLive.classList.add('hide-banner')
    bannerDefault.classList.remove('hide-banner')
}
