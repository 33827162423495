import $ from 'jquery'
import g from './globals'

if (g.elementInDom('#popup')) {
    $(document).ready(() => {
        var isPopup = document.querySelector('.popup').dataset.popupShow

        if (isPopup === 'show') {
            setTimeout(() => {
                $('#popup').fadeIn(1000)
        
                $('#popup-close').on('click', (e) => {
                    e.preventDefault()
                    $('#popup').fadeOut(1000)
                })
            }, 15000)
        }
    })
}

// POPUP
// if (!document.cookie.split(';').filter((item) => item.trim().startsWith('imagery_modal=isModal')).length) {
//     setTimeout(() => {
//         $('#popup').fadeIn(1000)

//         $('#popup-close').on('click', (e) => {
//             e.preventDefault()
//             $('#popup').fadeOut(1000)
//         })
//     }, 1000)
//     setCookie('imagery_modal', 'isModal', 1)
// } 
// // Clear Cookie
// // setCookie('imagery_modal', 'isNotModal', 0)

// function setCookie(cname, cvalue, exdays) {
//     var d = new Date();
//     d.setTime(d.getTime() + (exdays*24*60*60*1000));
//     var expires = "expires="+ d.toUTCString();
//     document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
//   }