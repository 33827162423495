import Swiper from 'swiper';
// import Swiper styles
import 'swiper/swiper-bundle.css';


$(document).ready(() => {
    $('.scrollSlider').each((index, obj) => {
        var slideWidth = $('#scrollSlider-' + index + ' .scrollSlide-' + index).width(),
            slideLength = $('#scrollSlider-' + index + ' .scrollSlide-' + index).length,
            sliderWidth = slideWidth * $('#scrollSlider-' + index + ' .scrollSlide-' + index).length,
            sliderDuration = Math.ceil(sliderWidth)

        const scrollSlideLength = $('#scrollSlider-' + index + ' .swiper-slide').length
        console.log(scrollSlideLength)
        console.log(scrollSlideLength > 1)

        var scroll_slider = new Swiper('#scrollSlider-' + index, {
            direction: 'horizontal',
            slidesPerView: 1,
            spaceBetween: 10,
            loop: scrollSlideLength > 1,
            centeredSlides: true,
            watchOverflow: true,
        })

        $('.slider-nav-next-' + index).on('click', (e) => { 
            scroll_slider.slideNext()
        })
        $('.slider-nav-prev-' + index).on('click', (e) => { 
            scroll_slider.slidePrev()
        })

        if (scrollSlideLength <= 1) {
            $('.scrollPinContainer-' + index + ' .slider-nav-wrapper').css("display", "none")
        }
        
        // for (var i = 0; i < scrollSlideLength; i++) {
        //     slideAnimation.to(".scrollSlide-" + index, 2, tlParams)
        // } 
    })
})


// $(document).ready(() => {
//     if (!md.mobile()) {
//         $('.scrollSlider').each((index, obj) => {
//             var mouseScroll;

//             $('#scrollSlider-' + index).on('wheel', (event) => {
//               mouseScroll = event.originalEvent.deltaY  
//             })

//             var scroll_slider = new swiper('#scrollSlider-' + index, {
//                 direction: 'horizontal',
//                 slidesPerView: 1,
//                 spaceBetween: 10,
//                 centeredSlides: true,
//                 mousewheel: true,
//                 on: {
//                     transitionEnd : () => {
//                         if (scroll_slider.isEnd) {
//                             console.log(mouseScroll)
//                             setTimeout(() => {
//                                 var offset = $('#scrollSlider-' + index).closest('.grid-container').next().offset()
//                                 offset.top += 70
//                                 $('html, body').animate({
//                                     scrollTop: offset.top,
//                                 });
//                             }, 500)
//                             setTimeout(() => {
//                                 scroll_slider.slideTo(0)
//                                 scroll_slider.mousewheel.disable()
//                             }, 500)
//                         }
//                     }
//                 }
//             })   
//         })
//     }
//     if (md.mobile()) {
//         $('.scrollSlider').each((index, obj) => {

//             var scroll_slider = new swiper('#scrollSlider-' + index, {
//                 direction: 'horizontal',
//                 slidesPerView: 1,
//                 spaceBetween: 10,
//                 centeredSlides: true,
//             })
    
//         })
//     }
    
// })