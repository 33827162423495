jQuery(document).ready(($) => {
    $('#menu-menu-left li').each((index, obj) => {
        $(obj).removeClass('active')
    })

    if ($(window).width() > 1023) {
        // Wine Clubs
        $('#menu-item-20').on('mouseover', function (e) {
            e.preventDefault();
            $(this).children('.sub-menu').show();
            $(this).children('.sub-menu').css({
                visibility: 'visible'
            });
        });
        $('.sub-menu')
            .on('mouseenter', function (e) {
                e.preventDefault();
            })
            .on('mouseleave', function (e) {
                e.preventDefault();
                $('.sub-menu').hide();
            });
        $('.menu-item').not('#menu-item-20').on('mouseenter', function (e) {
            e.preventDefault();
            $('#menu-item-20').children('.sub-menu').hide();
        })

        // Our Story
        $('#menu-item-21').on('mouseover', function (e) {
            e.preventDefault();
            $(this).children('.sub-menu').show();
            $(this).children('.sub-menu').css({
                visibility: 'visible'
            });
        });
        $('.sub-menu')
            .on('mouseenter', function (e) {
                e.preventDefault();
            })
            .on('mouseleave', function (e) {
                e.preventDefault();
                $('.sub-menu').hide();
            });
        $('.menu-item').not('#menu-item-21').on('mouseenter', function (e) {
            e.preventDefault();
            $('#menu-item-21').children('.sub-menu').hide();
        })

        // Visit Us
        $('#menu-item-19').on('mouseover', function (e) {
            e.preventDefault();
            $(this).children('.sub-menu').show();
            $(this).children('.sub-menu').css({
                visibility: 'visible'
            });
        });
        $('.sub-menu')
            .on('mouseenter', function (e) {
                e.preventDefault();
            })
            .on('mouseleave', function (e) {
                e.preventDefault();
                $('.sub-menu').hide();
            });
        $('.menu-item').not('#menu-item-19').on('mouseenter', function (e) {
            e.preventDefault();
            $('#menu-item-19').children('.sub-menu').hide();
        })

        // Visit Us Sub Menu
        $('#menu-item-86825').on('mouseover', function (e) {
            e.preventDefault();
            $(this).children('.sub-menu').show();
            $(this).children('.sub-menu').css({
                visibility: 'visible',
                top: 'calc(100% + 78px)'
            });
        });
        $('.sub-menu')
            .on('mouseenter', function (e) {
                e.preventDefault();
            })
            .on('mouseleave', function (e) {
                e.preventDefault();
                $('.sub-menu').hide();
            });
        $('.menu-item').not('#menu-item-86825').on('mouseenter', function (e) {
            e.preventDefault();
            $('#menu-item-86825').children('.sub-menu').hide();
        })

        // Shop Wines
        $('#menu-item-86534').on('mouseover', function (e) {
            e.preventDefault();
            $(this).children('.sub-menu').show();
            $(this).children('.sub-menu').css({
                visibility: 'visible',
            });
        });
        $('.sub-menu')
            .on('mouseenter', function (e) {
                e.preventDefault();
            })
            .on('mouseleave', function (e) {
                e.preventDefault();
                $('.sub-menu').hide();
            });
        $('.menu-item').not('#menu-item-86534').on('mouseenter', function (e) {
            e.preventDefault();
            $('#menu-item-86534').children('.sub-menu').hide();
        })

        // Events
        $('#menu-item-87271').on('mouseover', function (e) {
            e.preventDefault();
            $(this).children('.sub-menu').show();
            $(this).children('.sub-menu').css({
                visibility: 'visible',
            });
        });
        $('.sub-menu')
            .on('mouseenter', function (e) {
                e.preventDefault();
            })
            .on('mouseleave', function (e) {
                e.preventDefault();
                $('.sub-menu').hide();
            });
        $('.menu-item').not('#menu-item-87271').on('mouseenter', function (e) {
            e.preventDefault();
            $('#menu-item-87271').children('.sub-menu').hide();
        })

        // Corporate Gifting
        $('#menu-item-86871').on('mouseover', function (e) {
            e.preventDefault();
            $(this).children('.sub-menu').show();
            $(this).children('.sub-menu').css({
                visibility: 'visible',
            });
        });
        $('.sub-menu')
            .on('mouseenter', function (e) {
                e.preventDefault();
            })
            .on('mouseleave', function (e) {
                e.preventDefault();
                $('.sub-menu').hide();
            });
        $('.menu-item').not('#menu-item-86871').on('mouseenter', function (e) {
            e.preventDefault();
            $('#menu-item-86871').children('.sub-menu').hide();
        })

        // FIND
        $('#menu-find').on('mouseover', function (e) {
            e.preventDefault();
            $(this).children('.sub-menu').show();
            $(this).children('.sub-menu').css({
                visibility: 'visible'
            });
        });
        $('.sub-menu')
            .on('mouseenter', function (e) {
                e.preventDefault();
            })
            .on('mouseleave', function (e) {
                e.preventDefault();
                $('.sub-menu').hide();
            });
        $('.menu-item').not('#menu-find').on('mouseenter', function (e) {
            e.preventDefault();
            $('#menu-find').children('.sub-menu').hide();
        })
        
    } else if ($(window).width() < 1023) {

        $('.menu-item-has-children').each((index, obj) => {
            $(obj).children('.sub-menu').addClass('hide')

            $(obj).children('a').on('click', (e) => {
                e.preventDefault()
                $(obj).children('.sub-menu').toggleClass('hide')
                $(obj).toggleClass('sub-active');
            })
        })

    }

    $(window).on('resize', () => {
        if ($(window).width() > 1023) {
            // Wine CLubs
            $('#menu-item-20, #find').on('mouseover', function (e) {
                e.preventDefault();
                $(this).children('.sub-menu').show();
                $(this).children('.sub-menu').css({
                    visibility: 'visible'
                });
            });
            $('.sub-menu')
                .on('mouseenter', function (e) {
                    e.preventDefault();
                })
                .on('mouseleave', function (e) {
                    e.preventDefault();
                    $('.sub-menu').hide();
                });
            $('.menu-item').not('#menu-item-20, #find').on('mouseenter', function (e) {
                e.preventDefault();
                $('#menu-item-20').children('.sub-menu').hide();
            })

            // Our Story
            $('#menu-item-21').on('mouseover', function (e) {
                e.preventDefault();
                $(this).children('.sub-menu').show();
                $(this).children('.sub-menu').css({
                    visibility: 'visible'
                });
            });
            $('.sub-menu')
                .on('mouseenter', function (e) {
                    e.preventDefault();
                })
                .on('mouseleave', function (e) {
                    e.preventDefault();
                    $('.sub-menu').hide();
                });
            $('.menu-item').not('#menu-item-21').on('mouseenter', function (e) {
                e.preventDefault();
                $('#menu-item-21').children('.sub-menu').hide();
            })

            // Visit Us
            $('#menu-item-19').on('mouseover', function (e) {
                e.preventDefault();
                $(this).children('.sub-menu').show();
                $(this).children('.sub-menu').css({
                    visibility: 'visible'
                });
            });
            $('.sub-menu')
                .on('mouseenter', function (e) {
                    e.preventDefault();
                })
                .on('mouseleave', function (e) {
                    e.preventDefault();
                    $('.sub-menu').hide();
                });
            $('.menu-item').not('#menu-item-19').on('mouseenter', function (e) {
                e.preventDefault();
                $('#menu-item-19').children('.sub-menu').hide();
            })

            // Shop Wines
            $('#menu-item-86534').on('mouseover', function (e) {
                e.preventDefault();
                $(this).children('.sub-menu').show();
                $(this).children('.sub-menu').css({
                    visibility: 'visible'
                });
            });
            $('.sub-menu')
                .on('mouseenter', function (e) {
                    e.preventDefault();
                })
                .on('mouseleave', function (e) {
                    e.preventDefault();
                    $('.sub-menu').hide();
                });
            $('.menu-item').not('#menu-item-86534').on('mouseenter', function (e) {
                e.preventDefault();
                $('#menu-item-86534').children('.sub-menu').hide();
            })

            // Events
            $('#menu-item-87271').on('mouseover', function (e) {
                e.preventDefault();
                $(this).children('.sub-menu').show();
                $(this).children('.sub-menu').css({
                    visibility: 'visible'
                });
            });
            $('.sub-menu')
                .on('mouseenter', function (e) {
                    e.preventDefault();
                })
                .on('mouseleave', function (e) {
                    e.preventDefault();
                    $('.sub-menu').hide();
                });
            $('.menu-item').not('#menu-item-87271').on('mouseenter', function (e) {
                e.preventDefault();
                $('#menu-item-87271').children('.sub-menu').hide();
            })

            // Corporate Gifting
            $('#menu-item-86871').on('mouseover', function (e) {
                e.preventDefault();
                $(this).children('.sub-menu').show();
                $(this).children('.sub-menu').css({
                    visibility: 'visible'
                });
            });
            $('.sub-menu')
                .on('mouseenter', function (e) {
                    e.preventDefault();
                })
                .on('mouseleave', function (e) {
                    e.preventDefault();
                    $('.sub-menu').hide();
                });
            $('.menu-item').not('#menu-item-86871').on('mouseenter', function (e) {
                e.preventDefault();
                $('#menu-item-86871').children('.sub-menu').hide();
            })


            // FIND
            $('#menu-find').on('mouseover', function (e) {
                e.preventDefault();
                $(this).children('.sub-menu').show();
                $(this).children('.sub-menu').css({
                    visibility: 'visible'
                });
            });
            $('.sub-menu')
                .on('mouseenter', function (e) {
                    e.preventDefault();
                })
                .on('mouseleave', function (e) {
                    e.preventDefault();
                    $('.sub-menu').hide();
                });
            $('.menu-item').not('#find').on('mouseenter', function (e) {
                e.preventDefault();
                $('#find').children('.sub-menu').hide();
            })
        } else {
            
        }
    })




})